import React, { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import { CardContent, Box, Typography, Button, Grid } from '@mui/material';
import Player from '@vimeo/player';
import styles from './BlocItemStyles.module.css';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { BlocItemProps } from './models/Index';

const BlocItem: React.FC<BlocItemProps> = ({ bloc, index, courseId, blocId }) => {
  const [currentProsa, setCurrentProsa] = useState<string>('');
  const [currentNotationIndex, setCurrentNotationIndex] = useState<number | null>(null);
  const { user, isAdmin } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (bloc.videoUrl && typeof window !== 'undefined') {
      const player = new Player(`vimeo-player-${index}`, {
        url: `https://player.vimeo.com/video/${bloc.videoUrl}`,
        width: 640,
      });

      const isMobile = /Mobi|Android/i.test(navigator.userAgent);

      if (isMobile) {
        player.enableTextTrack('en', 'captions').then(function(track) {
          console.log('Captions enabled on mobile:', track);
        }).catch(function(error) {
          console.error('Error enabling captions on mobile:', error);
        });
      } else {
        player.enableTextTrack('en', 'captions').then(function(track) {
          console.log('Subtitles (transcripts) enabled on desktop:', track);
        }).catch(function(error) {
          console.error('Error enabling subtitles (transcripts) on desktop:', error);
        });
      }

      player.on('timeupdate', (data: { seconds: number }) => {
        const currentTime = data.seconds;
        const notationIndex = bloc.notationArray.findIndex(
          (notation, i) =>
            parseFloat(notation.time) <= currentTime &&
            (i === bloc.notationArray.length - 1 || currentTime < parseFloat(bloc.notationArray[i + 1].time))
        );
        if (notationIndex !== -1) {
          if (bloc.notationArray[notationIndex].prosa2) {
            setCurrentProsa(bloc.notationArray[notationIndex].prosa2);
          }
          setCurrentNotationIndex(notationIndex);
        }
      });
    }
  }, [bloc.videoUrl, bloc.notationArray, index]);

  const handleNotationClick = (time: string) => {
    const timeInSeconds = parseFloat(time);
    const iframe = document.getElementById(`vimeo-player-${index}`) as HTMLIFrameElement;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        { method: 'setCurrentTime', value: timeInSeconds },
        '*'
      );
    }
  };

  const navigateToAdminFeedback = () => {
    navigate(`/admin-feedback/${bloc.id}`);
  };

  const navigateToFeedback = () => {
    navigate(`/student-feedback/${bloc.id}`);
  };

  const navigateToNotationDetails = () => {
    if (currentNotationIndex !== null) {
      const currentNotation = bloc.notationArray[currentNotationIndex];
      navigate(`/notation-details`, { state: { notation: currentNotation } });
    }
  };

  const navigateToLessonsList = () => {
    if (!courseId) {
      console.error("courseId is undefined or null");
      return;
    }
    navigate(`/course/${bloc.courseId}`);
  };

  return (
    <CardContent>

      {bloc.description != '' ?
       <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(bloc.description) }} />
      : null
      }

      <div className={styles.notationContainer}>
        {bloc.notationArray.map((notation, notationIndex) => (
          <div
            key={notationIndex}
            className={`${styles.notationUnit} ${currentNotationIndex === notationIndex ? styles.active : ''}`}
            onClick={() => handleNotationClick(notation.time)}
          >
            <div className={styles.notationRow}>
              <strong className={styles.notationItem}>{notation.beat}.{notation.innerBeat}</strong>
            </div>
            <div className={styles.notationRow}>
              <div className={styles.notationItem}>{notation.sy}</div>
            </div>
            <div className={styles.notationRow}>
              <div className={styles.notationItem}>{notation.ln}{notation.la} {notation.lf} </div>
            </div>
            <div className={styles.notationRow}>
              <div className={styles.notationItem}>{notation.fn}{notation.fa} {notation.ff} </div>
            </div>
          </div>
          
        ))}
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {bloc.videoUrl && (
              <Box
                className={styles.videoContainer}
                sx={{
                  width: '100%',
                  height: 'auto',
                  border: 'none', // Removing the border here
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box
                  className={styles.videoBox}
                  sx={{
                    width: '100%',
                    height: 'auto',
                    position: 'relative',
                    maxWidth: '100%',
                  }}
                >
                  <iframe
                    id={`vimeo-player-${index}`}
                    src={`https://player.vimeo.com/video/${bloc.videoUrl}`}
                    title="Vimeo video player"
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      border: 'none',
                    }}
                    allowFullScreen
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          {currentProsa && (
            <Box sx={{ display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'center' }}>
              <div className={styles.prosaContainer} style={{ height: '100%' }}>
                <div className={styles.prosaContainer}>
                  <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(currentProsa) }} />
                </div> 
              </div>
            </Box>
          )}
        </Grid>
      </Grid>

      <div className={styles.buttonsContainer}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#FF7F50',
            color: 'white',
            margin: '10px',
            '&:hover': {
              backgroundColor: '#FF6347',
            },
            textTransform: 'none',
            fontWeight: 'bold',
          }}
          onClick={navigateToLessonsList}
        >
          Lessons List
        </Button>
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#00A3A3',
            color: 'white',
            margin: '10px',
            '&:hover': {
              backgroundColor: '#009999',
            },
            textTransform: 'none',
            fontWeight: 'bold',
          }}
          onClick={navigateToFeedback}
        >
          View Feedback
        </Button>
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#00897B',
            color: 'white',
            margin: '10px',
            '&:hover': {
              backgroundColor: '#00695C',
            },
            textTransform: 'none',
            fontWeight: 'bold',
          }}
          onClick={navigateToNotationDetails}
          disabled={currentNotationIndex === null}
        >
          Notation Details
        </Button>
        {isAdmin && (
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#FF7F50',
              color: 'white',
              margin: '10px',
              '&:hover': {
                backgroundColor: '#FF6347',
              },
              textTransform: 'none',
              fontWeight: 'bold',
            }}
            onClick={navigateToAdminFeedback}
          >
            View Admin Feedback
          </Button>
        )}
      </div>
      <br />
    </CardContent>
  );
};

export default BlocItem;
