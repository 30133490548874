import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Grid, Box } from '@mui/material';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase-config';
import theme from '../theme';
import { useAuth } from '../context/AuthContext';  // AuthContext will include subscription status
import { Bloc } from './models/Index';
import BlocCard from './BlocCard';
import LargeBlocCard from './LargeBlocCard';
import styles from './BlocListPage.module.css';

const BlocsListPage: React.FC = () => {
  const [blocs, setBlocs] = useState<Bloc[]>([]);
  const { user, isSubscribed } = useAuth();  // Add subscription check here
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlocs = async () => {
      try {
        const blocsCollection = collection(db, 'blocs');
        const blocsQuery = query(blocsCollection, orderBy('timestamp', 'desc'), limit(9));
        const blocsSnapshot = await getDocs(blocsQuery);

        const blocsData = blocsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        })) as Bloc[];

        setBlocs(blocsData);
      } catch (error) {
        console.error('Failed to fetch blocs:', error);
      }
    };

    fetchBlocs();
  }, []);

  const handleItemClick = (bloc: Bloc, index: number) => {
    if (!user && index >= 3) {
      navigate('/signin');
    } else if (user && !isSubscribed && index >= 6) {
      navigate('/subscribe');  // Redirect to subscribe page if not subscribed and tries to access beyond 6
    } else {
      navigate(`/bloc/${bloc.id}`);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box className={styles['grid-container']}>
        <Grid container spacing={2}>
          {/* Large card on top */}
          <Grid item xs={12} className={styles['grid-item']}>
            <LargeBlocCard />
          </Grid>

          {/* Other cards in a grid layout */}
          <Grid container item xs={12} spacing={2}>
            {blocs.slice(0, 9).map((bloc, index) => (
              <Grid item xs={12} sm={6} md={4} className={styles['grid-item']} key={bloc.id}>
                <BlocCard bloc={bloc} index={index} onClick={() => handleItemClick(bloc, index)} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default BlocsListPage;