import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useAuth } from '../context/AuthContext';
import { httpsCallable } from 'firebase/functions';
import { Button, Typography, CircularProgress, Box } from '@mui/material';
import { functions } from '../firebase-config';
import { getAuth } from 'firebase/auth';  // Make sure to import Firebase Auth



const stripePromise = loadStripe('pk_test_bzDsKCWmwQjwfIC1eH6n1LFF');

const SubscribePage: React.FC = () => {
    const { user } = useAuth();
    const [loading, setLoading] = useState(false);

    const handleSubscribe = async () => {
        setLoading(true);
        try {
            const auth = getAuth();
            const token = await auth.currentUser?.getIdToken();  // Get the JWT token
    
            const createCheckoutSession = httpsCallable<{ priceId: string, token: string | undefined }, { id: string }>(functions, 'createCheckoutSession');
            const { data: session } = await createCheckoutSession({
                priceId: 'TANGOVLOG_MONTHLY',
                token,  // Send the token along with the request
            });
    
            const stripe = await stripePromise;
            const { error } : any = await stripe?.redirectToCheckout({ sessionId: session.id });
    
            if (error) {
                console.error('Stripe checkout error', error);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error creating checkout session:', error);
            setLoading(false);
        }
    };

    return (
        <Elements stripe={stripePromise}>
            <Box 
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh',  // Make the page fill the height of the viewport
                    backgroundColor: '#f5f5f5',
                    padding: '20px'
                }}
            >
                <Typography 
                    variant="h4" 
                    gutterBottom
                    sx={{
                        color: '#5D1049',  // Customize text color
                        marginBottom: '24px',
                        textAlign: 'center'
                    }}
                >
                    Subscribe to Access All Content
                </Typography>

                <Typography 
                    variant="body1" 
                    sx={{
                        color: '#412039',  // Secondary text color
                        marginBottom: '24px',
                        textAlign: 'center',
                        maxWidth: '600px'  // Limit text width for readability
                    }}
                >
                    Unlock exclusive content and gain full access to lessons, tutorials, and much more. Subscribe now to begin your Tango journey!
                </Typography>

                {loading ? (
                    <CircularProgress />
                ) : (
                    <Button 
                        variant="contained" 
                        onClick={handleSubscribe} 
                        sx={{
                            backgroundColor: '#5D1049',  // Primary button color
                            color: 'white',
                            padding: '10px 20px',
                            fontSize: '16px',
                            '&:hover': {
                                backgroundColor: '#412039'  // Darker shade on hover
                            },
                            marginBottom: '16px'
                        }}
                    >
                        Subscribe Now
                    </Button>
                )}
            </Box>
        </Elements>
    );
};

export default SubscribePage;
