import React, { useEffect, useState } from 'react';
import { Typography, Box, CircularProgress } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase-config';  // Import Firebase config

const SuccessPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState<boolean | null>(null);  // Handle null state
  const sessionId = searchParams.get('session_id');

  useEffect(() => {
    const verifySession = async () => {
      if (!sessionId) {
        console.error('No session ID found in URL.');
        setSuccess(false);
        setLoading(false);
        return;
      }

      try {
        // Call the Cloud Function to verify the session
        const checkSession = httpsCallable<{ sessionId: string }, { success: boolean }>(
          functions, 'verifyCheckoutSession'
        );
        
        const result = await checkSession({ sessionId });

        console.log('Session verification result:', result); // Log the result for debugging
        setSuccess(result.data.success);  // Set the success state based on the response
      } catch (error) {
        console.error('Error verifying session:', error);
        setSuccess(false);
      } finally {
        setLoading(false);
      }
    };

    verifySession();
  }, [sessionId]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#f5f5f5',
      }}
    >
      {loading ? (
        <CircularProgress />
      ) : success ? (
        <>
          <Typography variant="h4" sx={{ color: '#5D1049', marginBottom: '16px' }}>
            Subscription Successful!
          </Typography>
          <Typography variant="body1" sx={{ color: '#412039' }}>
            Thank you for subscribing. You now have full access to all content.
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="h4" sx={{ color: '#5D1049', marginBottom: '16px' }}>
            Subscription Failed
          </Typography>
          <Typography variant="body1" sx={{ color: '#412039' }}>
            Something went wrong. Please contact support.
          </Typography>
        </>
      )}
    </Box>
  );
};

export default SuccessPage;
